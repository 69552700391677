import { jsPDF } from 'jspdf';
import { base64logo } from './base64logo';

const QRCode = require('qrcode');
import moment from 'moment';

function wnh(string) {
  return doc.getTextDimensions(string);
}

async function imprimir_manifiesto_js(manifiesto_info) {
  const doc = new jsPDF({
    orientation: 'h',
    unit: 'cm',
    format: 'A4',
  });

  /**
   *INICIALIZACION VARIABLES DEL DOCUMENTO
   */
  doc.setFont('arial', '');
  doc.setFontSize(14);

  let width = doc.internal.pageSize.getWidth();
  let halfWidth = doc.internal.pageSize.getWidth() * 0.6;
  let height = doc.internal.pageSize.getHeight();
  let marginLeft = 1.1;
  let marginRight = 1.1;
  let totConteoPaginas = Math.ceil(manifiesto_info.guias.length / 4);
  let no_pagina = 1;
  let no_guia = 1;
  let y_cursor = 1;
  let x_cursor = 0;
  console.log('Start');

  console.log(manifiesto_info.guias);

  for (let index = 0; index < manifiesto_info.guias.length; index++) {
    /**
     *       NEXT LINE
     * cursor init next line*/
    y_cursor = y_cursor + 0.5;
    x_cursor = marginLeft;

    /**
     * fecha de impresion
     */

    doc.setFont('arial', '');
    doc.setFontSize(6);
    // prettier-ignore
    // doc.text(moment().format('DD-MM-yyyy hh:mm a'), width-0.4, y_cursor+1.4, { angle: 90 });

    // prettier-ignore
    doc.text(`${manifiesto_info._id.piloto} - ${manifiesto_info._id.placa} - ${manifiesto_info._id.fecha}`, width-0.4, y_cursor+3.7, { angle: 90 });

    /**
     *
     * LOGO DEL SOFTWARE
     */
    // prettier-ignore
    doc.addImage(base64logo, 'jpeg', width - 3, y_cursor-0.65, 2, 0.7, 'logo', 'FAST', 0);

    /**
     *
     * CODIGO QR DE LA GUIA
     */
    const guiainfo = {
      A: manifiesto_info.guias[index]._id,
      B: manifiesto_info.guias[index].nombre,
      C: manifiesto_info.guias[index].direccion,
    };

    const qrCodeImage = await QRCode.toDataURL(JSON.stringify(guiainfo));
    console.log(manifiesto_info.guias[index]._id);
    console.log(qrCodeImage);
    // prettier-ignore
    doc.addImage(qrCodeImage, 'jpeg', x_cursor-.25, y_cursor-.5, 3.5, 3.5, manifiesto_info.guias[index]._id, 'FAST', 0);

    /**  */
    x_cursor = 3.2 + marginLeft;

    /**
     ***********************
     *Número de guía
     ***********************
     */
    doc.setFont('arial', '');
    doc.setFontSize(7);
    doc.text(
      'ID Manifiesto: ' + manifiesto_info._id.manifiesto,
      x_cursor,
      y_cursor
    );
    y_cursor = y_cursor + 0.5;
    // prettier-ignore
    doc.setFont('arial', '');
    doc.setFontSize(7);
    doc.text(`PEDIDO: `, x_cursor, y_cursor);
    x_cursor = x_cursor + doc.getTextDimensions(`PEDIDO: `).w; //width;
    doc.setFont('arial', 'bold');
    doc.setFontSize(10);
    // prettier-ignore
    doc.text(`${manifiesto_info.guias[index].guiaCodigo}`, x_cursor, y_cursor);

    /**
     *       NEXT LINE
     * cursor init next line*/
    y_cursor = y_cursor + 0.5;
    x_cursor = 3.2 + marginLeft;

    /**
     ***********************
     *DATOS DEL CLIENTE
     ***********************
     */
    // prettier-ignore
    doc.setFont('arial', '');
    doc.setFontSize(7);
    doc.text(`NOMBRE: `, x_cursor, y_cursor);
    x_cursor = x_cursor + doc.getTextDimensions(`NOMBRE: `).w; //width;
    doc.setFont('arial', 'bold');
    doc.setFontSize(10);
    doc.text(
      `${manifiesto_info.guias[index].guiaReceptor.guiaReceptorNombre}`,
      x_cursor,
      y_cursor
    );
    /**
     *       NEXT LINE
     * cursor init next line*/
    y_cursor = y_cursor + 0.5;
    x_cursor = 3.2 + marginLeft;

    // prettier-ignore
    doc.setFont('arial', '');
    doc.setFontSize(7);
    doc.text(`TELEFONO: `, x_cursor, y_cursor);
    x_cursor = x_cursor + doc.getTextDimensions(`TELEFONO: `).w; //width;
    doc.setFont('arial', 'bold');
    doc.setFontSize(10);
    doc.text(
      `${manifiesto_info.guias[index].guiaReceptor.guiaReceptorTelefono}`,
      x_cursor,
      y_cursor
    );

    /**
     *       NEXT LINE
     * cursor init next line*/
    y_cursor = y_cursor + 0.5;
    x_cursor = 3.2 + marginLeft;

    // prettier-ignore
    doc.setFont('arial', '');
    doc.setFontSize(7);
    doc.text(`DIRECCION: `, x_cursor, y_cursor);
    x_cursor = x_cursor + doc.getTextDimensions(`DIRECCION: `).w; //width;
    doc.setFont('arial', 'bold');
    doc.setFontSize(10);

    // prettier-ignore

    let doc_split_direccion = doc.splitTextToSize(
      manifiesto_info.guias[index].guiaReceptor.guiaReceptorDireccion,
      width - x_cursor - marginRight
    );
    doc.text(doc_split_direccion, x_cursor, y_cursor);

    //
    let dire_dimensiones = doc.getTextDimensions(doc_split_direccion);
    // prettier-ignore
    // x_cursor = x_cursor + doc.getTextDimensions(``).w; //width;
    // doc.text(`guia ${width} ${height}`, x_cursor, y_cursor);

    /**
     ***********************
     *Departamento y municipio
     ***********************
     */
    //siguiente linea
    y_cursor = y_cursor + dire_dimensiones.h + 0.1;
    x_cursor = 3.2 + marginLeft;

    /** etiqueta y dimensiones */
    doc.setFont('arial', '');
    doc.setFontSize(7);
    let doc_label_departamento = 'DEPARTAMENTO: ';
    let doc_label_departamento_dimensiones = doc.getTextDimensions(
      doc_label_departamento
    );
    //
    let var_horizontal_multi_line_depto_municipio = '';
    //
    doc.setFont('arial', '');
    doc.text(doc_label_departamento, x_cursor, y_cursor);
    //
    doc.setFont('arial', 'bold');
    doc.setFontSize(10);

    doc.text(
      manifiesto_info.guias[index].guiaReceptor.guiaReceptorDepartamento,
      doc_label_departamento_dimensiones.w + x_cursor + 0.1,
      y_cursor
    );
    // prettier-ignore
    var_horizontal_multi_line_depto_municipio = doc.getTextDimensions(
      doc_label_departamento +
        manifiesto_info.guias[index].guiaReceptor.guiaReceptorDepartamento
    );
    /** etiqueta y dimensiones */
    let doc_label_municipio = 'Municipio:';
    let doc_label_municipio_dimensiones = doc.getTextDimensions(
      doc_label_municipio
    );
    //
    doc.setFont('arial', '');
    doc.text(
      doc_label_municipio,
      var_horizontal_multi_line_depto_municipio.w + x_cursor,
      y_cursor
    );
    //
    doc.setFont('arial', 'bold');
    // prettier-ignore
    doc.text(
      manifiesto_info.guias[index].guiaReceptor.guiaReceptorMunicipio,
      var_horizontal_multi_line_depto_municipio.w +
        doc_label_municipio_dimensiones.w +
        x_cursor +
        0.1,
      y_cursor
    );
    /**
     ***********************
     *Departamento y municipio (FIN)
     ***********************
     */

    /**
     *
     * detalle de bultos de la guia
     *
     */
    y_cursor = y_cursor + 0.5;
    x_cursor = 3.5 + marginLeft;

    //siguiente linea
    y_cursor = y_cursor + 0.7;

    if (dire_dimensiones.h > 0.36) {
      y_cursor = y_cursor - (dire_dimensiones.h - 0.352777777777777);
    }

    /**
     * Copyright ©
     */

    doc.setFont('arial', '');
    doc.setFontSize(6);
    let ano = moment().format('yyyy');
    // prettier-ignore
    doc.text(`© ${ano} Datakalan S.A. Guatemala, Guatemala.`, 1, height-0.4);
    /** númeración de pagina */
    // prettier-ignore
    doc.text(`Página: ${no_pagina}/${totConteoPaginas}`,width-marginRight-1, height-0.4)

    /**
     * Copyright © (FIN)
     */

    /**
     ***********************
     *Detalle de los bultos [CUERPO]
     ***********************
     */
    let detallesContador = 0;

    /**
     *
     */

    if (manifiesto_info.guias[index].guiaDescripcion.length > 0) {
      doc.setFontSize(8);
      doc.text('Contenido de guía ', marginLeft, y_cursor);
      doc.setFontSize(10);
      manifiesto_info.guias[index].guiaDescripcion.forEach((bulto) => {
        detallesContador++;
        //siguiente linea
        y_cursor = y_cursor + 0.5;

        let var_horizontal_multi_line_detalle = '';
        let var_horizontal_multi_line_detalle_dimensiones = { w: 0, h: 0 };

        // DETALLLE TIPO
        doc.setFont('arial', '');
        if (bulto.tipo) {
          let doc_label_tipo = detallesContador.toString() + '). Tipo: ';
          // prettier-ignore
          doc.text(doc_label_tipo + ' ', marginLeft, y_cursor);
          // prettier-ignore
          var_horizontal_multi_line_detalle = doc_label_tipo;
          // prettier-ignore
          var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
          //
          doc.setFont('arial', 'bold');
          // prettier-ignore
          doc.text(bulto.tipo, marginLeft + var_horizontal_multi_line_detalle_dimensiones.w, y_cursor);
          // prettier-ignore
          var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + bulto.tipo.toString() + ': ';
          // prettier-ignore
          var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
          console.log('tipo: ', var_horizontal_multi_line_detalle);
        }

        //DETALLE CANTIDAD
        doc.setFont('arial', '');
        if (bulto.cantidad) {
          let doc_label_cantidad = 'Cantidad: ';
          let doc_label_cantidad_dimension = doc.getTextDimensions(
            doc_label_cantidad
          );
          // prettier-ignore
          doc.text(doc_label_cantidad, marginLeft + var_horizontal_multi_line_detalle_dimensiones.w, y_cursor);
          //
          doc.setFont('arial', 'bold');
          let textValue = bulto.cantidad.toString() + ' ';
          // prettier-ignore
          doc.text(textValue, marginLeft +  var_horizontal_multi_line_detalle_dimensiones.w + doc_label_cantidad_dimension.w, y_cursor);
          // prettier-ignore
          var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + doc_label_cantidad + textValue +"  ";
          // prettier-ignore
          var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
        }
        //DETALLE peso
        doc.setFont('arial', '');
        if (bulto.peso) {
          let doc_label_peso = 'peso: ';
          let doc_label_peso_dimension = doc.getTextDimensions(doc_label_peso);
          // prettier-ignore
          doc.text(doc_label_peso, marginLeft + var_horizontal_multi_line_detalle_dimensiones.w, y_cursor);
          //
          doc.setFont('arial', 'bold');
          let textValue = bulto.peso.toString() + ' ';
          // prettier-ignore
          doc.text(textValue, marginLeft +  var_horizontal_multi_line_detalle_dimensiones.w + doc_label_peso_dimension.w, y_cursor);
          // prettier-ignore
          var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + doc_label_peso + textValue +"  ";
          // prettier-ignore
          var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
        }
        //DETALLE dimension
        doc.setFont('arial', '');
        if (bulto.dimension != null) {
          console.log('dimension del manifiesto', bulto.dimension);
          let doc_label_dimension = 'dimension: ';
          let doc_label_dimension_dimension = doc.getTextDimensions(
            doc_label_dimension
          );
          // prettier-ignore
          doc.text(doc_label_dimension, marginLeft + var_horizontal_multi_line_detalle_dimensiones.w, y_cursor);
          //
          doc.setFont('arial', 'bold');
          let textValue = bulto.dimension.toString() + ' ';
          // prettier-ignore
          doc.text(textValue, marginLeft +  var_horizontal_multi_line_detalle_dimensiones.w + doc_label_dimension_dimension.w, y_cursor);
          // prettier-ignore
          var_horizontal_multi_line_detalle = var_horizontal_multi_line_detalle + doc_label_dimension + textValue +"  ";
          // prettier-ignore
          var_horizontal_multi_line_detalle_dimensiones = doc.getTextDimensions(var_horizontal_multi_line_detalle);
        }

        // DESCRIPCION
        //dividir direccion en espacios no mas grandes de "8"
        if (bulto.descripcion) {
          let doc_split_detalle = doc.splitTextToSize(
            bulto.descripcion,
            width - x_cursor - marginRight
          );
          //
          let doc_label_descripcion = 'Detalle: ';
          let doc_label_detalle_dimensiones = doc.getTextDimensions(
            doc_label_descripcion
          );
          //
          doc.setFont('arial', '');
          // prettier-ignore
          doc.text(doc_label_descripcion, var_horizontal_multi_line_detalle_dimensiones.w + 1.1 , y_cursor);
          doc.setFont('arial', 'bold');
          // prettier-ignore
          doc.text(doc_split_detalle, var_horizontal_multi_line_detalle_dimensiones.w + doc_label_detalle_dimensiones.w + 1.1, y_cursor);
        }
      });
    }

    /**
     ***********************
     * FIN Detalle de los bultos [CUERPO] [BULTOS LOOP]
     ***********************
     */

    /**
     * lineas para comentarios
     */

    y_cursor = y_cursor + 0.7;
    doc.text('NOMBRE: ', marginLeft, y_cursor);
    doc.line(
      marginLeft + doc.getTextDimensions(`NOMBRE: `).w,
      y_cursor,
      width - marginRight,
      y_cursor,
      'F'
    );

    y_cursor = y_cursor + 0.6;
    doc.text('DPI: ', marginLeft, y_cursor);
    doc.line(
      marginLeft + doc.getTextDimensions(`DPI: `).w,
      y_cursor,
      halfWidth - marginRight,
      y_cursor,
      'F'
    );

    doc.text(
      'FIRMA: ',

      width * 0.53 + doc.getTextDimensions(`DPI: `).w,
      y_cursor
    );
    doc.line(
      width * 0.53 +
        doc.getTextDimensions(`DPI: `).w +
        doc.getTextDimensions(`FIRMA: `).w,
      y_cursor,
      width - marginRight,
      y_cursor,
      'F'
    );

    y_cursor = y_cursor + 0.4;

    doc.setLineDashPattern([0.2, 0.3, 0.1, 0.3], 0);
    doc.setLineWidth(0.02);
    y_cursor = y_cursor + 0.3;
    doc.line(marginLeft, y_cursor, width - marginRight, y_cursor);
    y_cursor = y_cursor + 0.4;

    /**
     *
     * FIN IMPRESION DATOS DE 1 GUIA
     */

    if (no_guia % 4 == 0 && index < manifiesto_info.guias.length - 1) {
      no_pagina++;
      doc.addPage();
      /**
       * Copyright ©
       */

      doc.setFont('arial', '');
      doc.setFontSize(6);
      let ano = moment().format('yyyy');
      // prettier-ignore
      doc.text(`© ${ano} Datakalan S.A. Guatemala, Guatemala.`, 1, height-0.4);
      /** númeración de pagina */
      // prettier-ignore
      doc.text(`Página: ${no_pagina}/${totConteoPaginas}`,width-marginRight-1, height-0.4)

      //**************************
      y_cursor = 1;

      //***********************[[***INICIO DETALLE DE MANIFIESTO PEDIDOS***]]*********************** [2NDA PARTE]
      //***********************[[***INICIO DETALLE DE MANIFIESTO PEDIDOS***]]*********************** [2NDA PARTE]
      ///**************************ENCABEZADO
      //prettier-ignore
      doc.text(`Manifiesto ${manifiesto_info._id.piloto}, placa ${manifiesto_info._id.placa}, fecha: ${manifiesto_info._id.fecha}`, 40, 30, {
      width: 300,
      align: 'left',
    });

      // Scale the image
      // doc.image(base64, 250, 25, { width: 100 });

      /******************Titulo de hoja***************** */

      doc.text('MANIFIESTO DE GUÍAS', 203, 30, {
        width: 300,
        align: 'left',
      });

      // fecha esquina superior izquierda
      //primera hoja encabezado
      let date = moment().locale('es').format('DD MMMM YYYY h:mm a');
      doc.text(date, 465, 30, {
        width: 300,
        align: 'left',
      });
    }
    no_guia++;
  }

  console.log('End');

  /** print in a new tab */
  doc.output('dataurlnewwindow');
}

export { imprimir_manifiesto_js };
